export default {
  OpenMetaActionsEvent: 'nsOpenMetaActions',
  CloseMetaActionsEvent: 'nsCloseMetaActions',
  ReportNotificationOpen: 'nsReportNotificationOpenRequested',
  ReportNotificationLoaded: 'nsReportNotificationLoaded',
  ReportNotificationSubmit: 'nsReportNotificationSubmit',
  ReportNotificationSubmitSuccess: 'nsReportNotificationSubmitSuccess',
  ReportNotificationSubmitFailed: 'nsReportNotificationSubmitFailed',
  ReportNotificationCancelled: 'nsReportNotificationCancelled'
};
