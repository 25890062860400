import React from 'react';
import { render } from 'react-dom';
import { NotificationData } from '../types/NotificationTemplateTypes';

import SendrNotification from '../components/SendrNotification';
import NotificationStreamModalContainer from '../containers/NotificationStreamModalContainer';

export const renderSendrNotification = (entry: Element): void => {
  const notificationDataJSON: string | null = entry.getAttribute('notification-data');
  if (!notificationDataJSON) {
    return;
  }
  if (notificationDataJSON === '{{notification}}') {
    // Notification data has not been populated from angular yet
    window.requestAnimationFrame(() => {
      renderSendrNotification(entry);
    });
    return;
  }
  const notificationData: NotificationData = JSON.parse(notificationDataJSON) as NotificationData;
  render(<SendrNotification notificationData={notificationData} />, entry);
};

export const renderSendrModalContainer = (entry: Element): void => {
  render(<NotificationStreamModalContainer />, entry);
};
